import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";

import Footer from "./global-components/footer-v3";
import Features from "./section-components/Features2";
import CTA from "./section-components/cta";
import Cta from "./section-components/cta";

const Customer = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Customer Story" />
      <Features aClass="agency_featured_area_two" />
      <div className="container" style={{paddingBottom:"120px",paddingTop:"120px"}}>
        <h1 className="text-center">KeyCite Media Review About Our Service</h1>
        <p></p>
        <div className="thm-breadcrumb">
        <iframe width="450" height="700" src="https://www.youtube-nocookie.com/embed/mNKVmcwMIM4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ></iframe>
        </div>
        
      </div>
      <Cta></Cta>

      <Footer />
    </div>
  );
};

export default Customer;
