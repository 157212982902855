import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class BannerV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="banner-three  go-top">
        <div className="banner-three__circle-1" />
        {/* /.banner-three__circle-1 */}
        <div className="banner-three__circle-2" />
        {/* /.banner-three__circle-2 */}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-9">
              <div className="banner-three__content">
                <h3>
                  <span>Automate </span> <br /> Your Chats, <br />{" "}
                  <span>Elevate </span> <br /> Your Business
                  <br />{" "}
                </h3>
                <p>We are professional &amp; Experiene Digital Agency</p>

                <Link to="/contact" className="thm-btn banner-three__btn">
				Contact Now <i className="fa fa-angle-double-right" />
                </Link>

               
                {/* /.thm-btn banner-three__btn */}
              </div>
              {/* /.banner-three__content */}
            </div>
            {/* /.col-lg-6 */}

            <img
              src={publicUrl + "assets/images/final/banner.svg"}
              alt={imagealt}
              className="banner-three__moc"
            />
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default BannerV3;
