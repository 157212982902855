import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Contact extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <section className="contact-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-two__content">
                  <div className="contact-two__block">
                    <h3>Don't Hesitate To Contact Us</h3>
                    <p>
                      We are here to assist you and provide prompt solutions to
                      any inquiries or concerns you may have. Feel free to reach
                      out to us, and we'll be delighted to help!
                    </p>
                  </div>
                  {/* /.contact-two__block */}
                  <ul className="list-unstyled contact-two__info-list">
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-map-marked" />
                        Address :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        Harrington Crescent
                        <br />
                        North York, ON, Canada <br></br>
                        Arlington St,
                        <br></br>
                        Winnipeg, MB, Canada{" "}
                      </p>
                    </li>
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-phone" />
                        Call Us :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        <a href="tel:647-673-9886 ">647 673 9886</a>
                      </p>
                    </li>
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-envelope" />
                        Email Us :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        <a href="mailto:nghiadangtran1@gmail.com">
                          nghiadangtran1@gmail.com
                        </a>
                      </p>
                    </li>
                  </ul>
                  {/* /.contact-two__info-list */}
                </div>
                {/* /.contact-two__content */}
              </div>
              {/* /.col-lg-5 */}
              <div className="col-lg-7">
                <form  action="https://formspree.io/f/xwkdkodk"
                  method="POST"

className="contact-two__form">
                  <div className="contact-two__block">
                    <h3>Get In Touch</h3>
                    <p>
                      <p>
                        Don't hesitate to contact us, as we strive to ensure
                        your satisfaction and exceed your expectations.
                      </p>
                    </p>
                  </div>
                  {/* /.contact-two__block */}
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text"  name="name" placeholder="Full Name" />
                    </div>
                    {/* /.col-md-6 */}
                    <div className="col-md-6">
                      <input type="text"  name="email" placeholder="Email Address" />
                    </div>
                    {/* /.col-md-6 */}
                    <div className="col-md-12">
                      <input type="text"  name="subject" placeholder="Subject" />
                    </div>
                    {/* /.col-md-12 */}
                    <div className="col-md-12">
                      <textarea
                        name="message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                      <button
                        type="submit"
                        className="thm-btn contact-two__btn"
                      >
                        Send Message <i className="fa fa-angle-double-right" />
                      </button>
                      {/* /.thm-btn contact-two__btn */}
                    </div>
                    {/* /.col-md-12 */}
                  </div>
                  {/* /.row */}
                </form>
                {/* /.contact-two__form */}
              </div>
              {/* /.col-lg-7 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /.contact-two */}
        <iframe
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJdYyRxC4yK4gRPX6_eocNM_s&key=AIzaSyCHOb2Pm0CvTix3l6opsh-h7UFdzkTZyH0"
          className="google-map__contact"
        ></iframe>
      </div>
    );
  }
}

export default Contact;
