import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";
class Featuresitems extends Component {
  render() {
    var {
      rowClass,
      fimage,
      iImg,
      ftitle,
      descriptions,
      aClass,
      embedSize,
      embed,
    } = this.props;
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    return (
      <div className={`agency_featured_item ${rowClass} `}>
        <div className="col-lg-6">
          <Reveal effect="fadeInLeft">
            <div className="agency_featured_img text-right">
              {!embed ? (
                <img
                  src={publicUrl + "assets/images/final/" + fimage}
                  alt={imagealt}
                />
              ) : (
                <iframe
                  width={embedSize[0]}
                  height={embedSize[1]}
                  src={"https://www.youtube-nocookie.com/embed/" + embed}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              )}

              {/* <img src={require("../../../public/assets/images/final/work1.png") } alt=""/> */}
            </div>
          </Reveal>
        </div>
        <div className="col-lg-6">
          <div
            className={`agency_featured_content wow fadeInLeft ${aClass}`}
            data-wow-delay="0.6s"
          >
            <Reveal effect="fadeInRight">
              <div className="dot">
                <span className="dot1"></span>
                <span className="dot2"></span>
              </div>

              {/* <img   className="number" src={publicUrl+"assets/images/final/"+iImg} alt={ imagealt }  /> */}

              {/* <img className="number" src={require ("../../img/home4/" + iImg)} alt=""/> */}
              <h3>{ftitle}</h3>
              {descriptions.replace(/\r\n/g, '\n').split('\n').map((data,index) => {
                return <p key={"random"}>{data}</p>;
              })}
              {/* <a href=".#" className="icon mt_30"><i className="ti-arrow-right"></i></a> */}
            </Reveal>
          </div>
        </div>
      </div>
    );
  }
}

export default Featuresitems;
