import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v3';
import Service from './section-components/service-v2';
import AboutV3 from './section-components/about';
import AboutV4 from './section-components/about-v4';
import Video from './section-components/video';

import Faq from './section-components/faq';

import Footer from './global-components/footer-v3';
import Contact from './section-components/contact';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Service />
        <AboutV3 />
        <AboutV4 />
        <Video />
        <Faq />
        <Contact />
        <Footer />
    </div>
}

export default Home_V1

