import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';

import Footer from './global-components/footer-v3';
import Features from './section-components/Features';
import CTA from './section-components/cta';

const AboutPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="About Us"  />
        <Features aClass="agency_featured_area_two"/>
        <CTA />
        <Footer />
    </div>
}

export default AboutPage

