import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-four  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-6">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>Multiple Function using Intelligent Chatbot </h3>
		          </div>{/* /.block-title-two text-left */}
		          <p>Chatbots can help you promote your product and reach a wider market, it can also be used for a number of purposes.</p>
		          <div className="about-four__box-wrap">
		            <div className="about-four__box">
		              <div className="about-four__box-icon">
					  
		                <i className="fal fa-light fa-universal-access" />
		              </div>{/* /.about-four__box-icon */}
		              <div className="about-four__box-content">
		                <h3>Customer Service</h3>
		                <p>While a customer support representative checks the inventory manually, a chatbot can provide a prompt answer by looking through the site's database.</p>
		              </div>{/* /.about-four__box-content */}
		            </div>{/* /.about-four__box */}
		            <div className="about-four__box">
		              <div className="about-four__box-icon">
		                <i className="fal fa-duotone fa-megaphone" />
		              </div>{/* /.about-four__box-icon */}
		              <div className="about-four__box-content">
		                <h3>Marketing</h3>
		                <p>Chatbots take a conversational approach towards lead generation, as they collect customer data by engaging users in a conversation and they also score leads.</p>
		              </div>{/* /.about-four__box-content */}
		            </div>{/* /.about-four__box */}
		            <div className="about-four__box">
		              <div className="about-four__box-icon">
		                <i className="fal fa-solid fa-user" />
		              </div>{/* /.about-four__box-icon */}
		              <div className="about-four__box-content">
		                <h3>Sales</h3>
		                <p>AI chatbots can considerably reduce recruitment costs and time taken to hire by single-handedly screening candidates and collecting information.</p>
		              </div>{/* /.about-four__box-content */}
		            </div>{/* /.about-four__box */}
		          </div>{/* /.about-four__box-wrap */}
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
		      <div className="col-lg-6">
		        <img src={publicUrl+"assets/images/final/whyUsed.svg"} className="about-four__moc" alt={ imagealt } />
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default AboutV4