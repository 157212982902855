import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Contact extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <section className="contact-one">
        <div className="container">
          <div className="block-title-two text-center">
            <p>get a quote</p>
            <h3>
              Don't Hesitate To Contact <br /> With Us Get A Call
            </h3>
          </div>
          {/* /.block-title-two */}
          <div className="row">
            <div className="col-lg-5">
              <div
                className="contact-one__box"
                style={{
                  backgroundImage:
                    "url(" + publicUrl + "assets/images/final/contact-1-1.jpg)",
                }}
              >
                <div className="contact-one__box-inner">
                  <h3>
                    Do You Have <br /> Any Questions?
                  </h3>
                  <p>
                    <br></br>
                  </p>
                  <a href="/contact" className="thm-btn contact-one__box-btn">
                    Contact Us <i className="fa fa-angle-double-right" />
                  </a>
                  {/* /.thm-btn contact-one__box-btn */}
                </div>
                {/* /.contact-one__box-inner */}
              </div>
              {/* /.contact-one__box */}
            </div>
            {/* /.col-lg-5 */}
            <div className="col-lg-7 d-flex">
              <div className="my-auto">
                <form
                  action="https://formspree.io/f/xwkdkodk"
                  method="POST"

                  className="contact-one__form"
                >
                  <h3>Plase Fill Up The Form To Contact With Us</h3>
                  <p>
                    <br></br>
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-user" />
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Full Name"
                        />
                      </div>
                      {/* /.contact-one__input-group */}
                    </div>
                    {/* /.col-lg-6 */}
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-envelope" />
                        <input
                          type="text"
                          required
                          name="email"
                          placeholder="Your Email "
                        />
                      </div>
                      {/* /.contact-one__input-group */}
                    </div>
                    {/* /.col-lg-6 */}
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-book-open-reader" />
                        <input
                          type="text"
                          name="phone"
                          placeholder="Your Subject"
                        />
                      </div>
                      {/* /.contact-one__input-group */}
                    </div>
                    {/* /.col-lg-6 */}
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-phone" />
                        <input
                          type="text"
                          name="phone"
                          placeholder="Your Phone"
                        />
                      </div>
                      {/* /.contact-one__input-group */}
                    </div>
                    {/* /.col-lg-6 */}
                    <div className="col-lg-12">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-pencil-alt" />
                        <textarea
                          name="message"
                          required
                          placeholder="Write Message"
                          defaultValue={""}
                        />
                      </div>
                      {/* /.contact-one__input-group */}
                    </div>
                    {/* /.col-lg-12 */}
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="thm-btn contact-one__form-btn"
                      >
                        Send Message <i className="fa fa-angle-double-right" />
                      </button>
                      {/* /.thm-btn contact-one__form-btn */}
                    </div>
                    {/* /.col-lg-12 */}
                  </div>
                  {/* /.row */}
                </form>
                {/* /.contact-one__form */}
              </div>
              {/* /.my-auto */}
            </div>
            {/* /.col-lg-7 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default Contact;
