import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return	<section className="service-three  go-top">
		  <div className="container">
		    <div className="block-title-two text-center">
		      <h3>Your premier automation partner.<br /> We're unique, modern, and sleek.<br />Transforming your ideas into streamlined solutions.</h3>
		    </div>{/* /.block-title-two text-center */}
		    <div className="row">
		      <div className="col-xl-3 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-star" />
		          <h3><Link to="/service">Idea Incubation</Link></h3>
		          <p>We nurture and refine your ideas before automation begins.</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-3 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-umbrella" />
		          <h3><Link to="/service">Efficient Automation</Link></h3>
		          <p>We transform your ideas into efficient chatbots and systems.</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-3 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-laptop-code" />
		          <h3><Link to="/service">Seamless Integration</Link></h3>
		          <p>Our solutions integrate perfectly with your current operations.</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-3 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-database" />
		          <h3><Link to="/service">Continuous Support</Link></h3>
		          <p>Our dedicated team is always ready to assist you.</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        }
}

export default ServiceV2