import React, { Component } from "react";
import Featuresitems from "./Featuresitems";

class Features extends Component {
  render() {
    var { aClass } = this.props;
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    return (
      <section className={`agency_featured_area bg_color ${aClass}`}>
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center"
          >
            {" "}
            Automate Your Chats, Elevate Your Business
          </h2>
          <p className="text-center">
            At AIAS, we revolutionize customer interactions by blending
            traditional chatbot design and cutting-edge AI technologies{" "}
            <br></br>For precise responses and command executions.
          </p>
          <div className="features_info">
            <img
              className="dot_img"
              src={publicUrl + "assets/images/final/dot.png"}
              alt={imagealt}
            />
            {/* <img className="dot_img" src={require ('../../img/home4/dot.png')} alt=""/> */}
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="work1.png"
              iImg="icon01.png"
              ftitle="Pioneering a New Era"
              descriptions="AIAS was established in 2023 with a singular vision: to revolutionize the landscape of customer interactions. Our founders identified a significant gap in the market where businesses were in dire need of an intelligent, robust tool that could interact with customers effectively and efficiently, answer their questions, and comprehend their needs in real-time."
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="work2.png"
              iImg="icon02.png"
              ftitle="Innovating with a Unique Approach"
              descriptions="Our methodology distinguishes us in the field. We harmonize traditional chatbot design with dynamic AI capabilities, allowing our chatbots to deliver both pre-set and contextually learned responses to a broad spectrum of user inputs. Our chatbots are designed for continuous learning and improvement, enhancing their understanding of user intent and context with each interaction. This translates into relevant and accurate responses, adeptly handling a diverse range of tasks from answering intricate queries to engaging in casual conversation."
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="work3.png"
              iImg="icon3.png"
              ftitle="Committed to Delivering Value"
              descriptions="AIAS is driven by a strong commitment to add exceptional value to our clients' operations. Our intelligent chatbots, designed for efficiency and enhanced customer experience, provide round-the-clock support. We cater to businesses of diverse scales and sectors, extending continuous, comprehensive support to ensure that our chatbots evolve with changing business needs and consistently deliver expected outcomes."
            />
            <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
