import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Video extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="video-one  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="video-one__box wow fadeInLeft"
                data-wow-duration="1500ms"
              >
                <img
                  src={publicUrl + "assets/images/final/knn1.jpg"}
                  alt={imagealt}
                />
                  <a href="https://www.youtube.com/watch?v=mNKVmcwMIM4" className="video-popup video-one__btn"><i className="fa fa-play" /></a>{/* /.video-popup video-one__btn */}

            
              </div>
              {/* /.video-one__box */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6 d-flex">
              <div className="my-auto">
                <div className="video-one__content">
                  <div className="block-title-two text-left">
                    <p>Customer Story</p>
                    <h3>AIAS Chatbot: KeyCite Media's Success Story</h3>
                  </div>
                  {/* /.block-title-two */}
                  <p>
                    AIAS revolutionized KeyCite Media's customer service
                    operations by implementing a state-of-the-art AI chatbot.
                    The advanced solution, tailored to KeyCite's specific needs,
                    offers instant, personalized support around the clock and
                    seamlessly transitions to human agents for complex queries.
                    With AIAS's continuous monitoring and updates, KeyCite Media
                    now effectively manages customer inquiries, enhancing
                    satisfaction and promoting growth. Partner with AIAS to
                    transform your customer service experience with AI-driven
                    solutions.
                  </p>
                  <ul className="list-unstyled video-one__list">
                    <li>
                      <i className="far fa-check" />
                      Developed a tailor-made AI chatbot for KeyCite Media,
                      enhancing their customer service interactions.
                    </li>
                    <li>
                      <i className="far fa-check" />
                      Provided instant, personalized customer support around the
                      clock, ensuring optimal customer satisfaction.
                    </li>
                    <li>
                      <i className="far fa-check" />
                      For complex queries, the system smoothly transitions from
                      the chatbot to a real agent, enhancing the customer
                      experience.
                    </li>
                    <li>
                      <i className="far fa-check" />
                      Ensures the chatbot's performance remains top-notch with
                      regular updates and continuous performance monitoring,
                      aiding KeyCite Media's growth.
                    </li>
                  </ul>
                  {/* /.list-unstyled video-one__list */}
                  <Link to="/customer" className="thm-btn">
                    Read More <i className="fa fa-angle-double-right" />
                  </Link>
                  {/* /.thm-btn */}
                </div>
                {/* /.video-one__content */}
              </div>
              {/* /.my-auto */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default Video;
