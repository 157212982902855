import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomeV3 from './components/home-v3';
import About from './components/about';
import ServiceV2 from './components/service-v2';

import Contact from './components/contact';
import Customer from './components/customer';


class Root extends Component {
    render() {
        return(
                <Routes>
	              
	             
	                    <Route  path="/" element={<HomeV3></HomeV3>} />
	                    <Route path="/about" element={<About></About>} />
	                    <Route path="/service" element={<ServiceV2></ServiceV2>} />
	               
	                    <Route path="/customer" element={<Customer></Customer>} />
	              
	         
	                    <Route path="/contact" element={<Contact></Contact>} />
	         
                </Routes>
        )
    }
}

export default Root;

ReactDOM.render(
<Router><Root /></Router>, document.getElementById('bizkar'));
