import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV6 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-two service-two__service-page  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-5">
			        <div className="service-two__block">
			          <div className="block-title-two text-left">
					  
			            <h3>AI Chatbot Development
</h3>
			          </div>{/* /.block-title-two */}
			          <p>Our intelligent solutions combine the best of traditional case-based design and cutting-edge AI technologies. They are capable of addressing a wide array of user commands and queries, delivering accurate responses, and learning from each interaction for continuous improvement.</p>
			          <ul className="list-unstyled video-one__list">
			            <li>
			              <i className="far fa-check" />
			              <span style={{fontWeight:"bold"}}>Customized Chatbot Solutions:</span>Each solution is uniquely crafted to align with your business needs, objectives, and brand persona.
			            </li>
			            <li>
			              <i className="far fa-check" />
						  <span style={{fontWeight:"bold"}}>Case-Based Responses:</span>
						  Our chatbots are designed to provide pre-defined responses to specific queries.
			            </li>
			            <li>
			              <i className="far fa-check" />
						  <span style={{fontWeight:"bold"}}>Intention Recognition:</span>
						  Leveraging AI, we ensure our solutions understand the context and intent behind user queries for more relevant and accurate responses.
			            </li>
			            <li>
			              <i className="far fa-check" />
						  <span style={{fontWeight:"bold"}}>Continuous Learning:</span>
						  Every interaction helps our solutions evolve, improving their understanding and response accuracy.
			            </li>
			          </ul>{/* /.list-unstyled video-one__list */}
			      
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}
			      <div className="col-lg-7 d-flex justify-content-center">
			        <div className="faq-two__image">
			          <img className='wow fadeInUp' src={publicUrl+"assets/images/final/Asset 11.svg"} alt="imagealt" />
			        </div>{/* /.faq-two__image */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>


        }
}

export default ServiceV6