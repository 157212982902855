import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Faq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="faq-one">
            <div className="container">
              <div className="row">
              
                <div className="col-lg-6">
                  <div className="faq-one__block">
                    <div className="block-title-two text-left">
                      <h3>Frequently Asked <br /> Questions</h3>
                    </div>{/* /.block-title-two */}
                    <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                      <div className="accrodion active">
                        <div className="accrodion-title">
                          <h4>What is unique about AIAS's chatbots?
</h4>
                        </div>
                        <div className="accrodion-content">
                          <div className="inner">
                            <p>Our chatbots uniquely combine traditional case-based design with advanced AI. This means they can provide pre-defined responses and also learn from interactions to understand and respond to a wide array of user commands and questions.</p>
                          </div>{/* /.inner */}
                        </div>
                      </div>
                      <div className="accrodion ">
                        <div className="accrodion-title">
                          <h4>How do AIAS's chatbots improve over time?
</h4>
                        </div>
                        <div className="accrodion-content">
                          <div className="inner">
                            <p>Our chatbots are designed to grow smarter with every interaction. They understand the context and intent behind user queries, which allows them to provide increasingly accurate and relevant responses over time.

</p>
                          </div>{/* /.inner */}
                        </div>
                      </div>
                      <div className="accrodion">
                        <div className="accrodion-title">
                          <h4>What value do AIAS's chatbots provide to businesses?
</h4>
                        </div>
                        <div className="accrodion-content">
                          <div className="inner">
                            <p>Our chatbots deliver significant value by offering instant, 24/7 support to customers. This improves the overall customer service experience, saves time, and optimizes resources for businesses.

</p>
                          </div>{/* /.inner */}
                        </div>
                      </div>
                      <div className="accrodion">
                        <div className="accrodion-title">
                          <h4>What kind of support can I expect after implementing a chatbot?</h4>
                        </div>
                        <div className="accrodion-content">
                          <div className="inner">
                            <p>We provide ongoing support to all our clients to ensure their chatbot systems continue to meet their evolving business needs. Our dedicated team is always ready to assist with any issues or questions.

</p>
                          </div>{/* /.inner */}
                        </div>
                      </div>
                    </div>
                  </div>{/* /.faq-one__block */}
                </div>{/* /.col-lg-6 */}
                <div className="col-lg-6 d-flex">
                  <div className="my-auto">
                    <div className="cta-three__image">
                      <img src={publicUrl+"assets/images/shapes/cta-3-1-shape-2.png"} alt={ imagealt } className="cta-three__image-shape" />
                      <img src={publicUrl+"assets/images/final/cta-3-2.jpg"} alt={ imagealt } />
                    </div>{/* /.cta-three__image */}
                  </div>{/* /.my-auto */}
                </div>{/* /.col-lg-6 */}
              </div>{/* /.row */}
            </div>{/* /.container */}
          </section>
        }
}

export default Faq