import React, { Component } from "react";
import Featuresitems from "./Featuresitems";

class Features extends Component {
  render() {
    var { aClass } = this.props;
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    return (
      <section className={`agency_featured_area bg_color ${aClass}`}>
        <div className="container">
        <h1 className="text-center">
        Introducing KeyCite Media

        </h1>
          <div className="features_info">
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="work1.png"
              iImg="icon01.png"
              ftitle="About KeyCite Media"
              descriptions="KeyCite Media is a distinguished Vietnamese media and advertising company, providing a wide range of services from television production to crisis management. As they expanded their operations, maintaining efficient, round-the-clock customer service, particularly for international clientele preferring English communication, became a challenge."
              embed="dc1AhKy6YTc"
              embedSize={[600, 352]}
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="knn2.jpg"
              iImg="icon02.png"
              ftitle="Identifying the Problem and Forming the Partnership"
              descriptions={`Recognizing the complexities and demands of their customer interactions, KeyCite Media sought a solution that could provide instantaneous responses, personalized experiences, and facilitate seamless transitions to human agents for complex queries. The idea of integrating an AI chatbot into their customer service operations became a viable solution.

In the quest for a reliable partner to aid in this journey, they discovered our company, AIAS. We specialize in crafting advanced, tailor-made chatbot solutions, with the goal of transforming customer interactions, optimizing operations, and boosting growth.

The possibility of a chatbot that combined the best of traditional case-based design with cutting-edge AI technologies, capable of learning from each interaction, seemed like the perfect fit for KeyCite Media's needs.`}
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="knn1.jpg"
              iImg="icon01.png"
              ftitle="Implementing the Solution and Seeing Results"
              descriptions={`Upon forming the partnership, our team at AIAS dedicated substantial time to understanding KeyCite Media's varied services, business objectives, and customer demographics. With this insight, we tailored our AI chatbot to suit their specific needs, including the cross-cultural requirement of providing customer service in English.

Post-implementation, KeyCite Media saw immediate improvements in their customer service metrics. The AI chatbot, with its 24/7 availability and instant responses, significantly enhanced customer satisfaction by reducing wait times. Moreover, the chatbot continued to learn from every interaction, consistently improving its understanding and response accuracy.

Simultaneously, the human customer support agents at KeyCite Media found the chatbot extremely useful. It proficiently handled routine queries, allowing them to concentrate on complex issues requiring their expertise.

The integration of the AIAS chatbot marked a turning point for KeyCite Media. It not only elevated their operational efficiency but also boosted their brand reputation in the global market. AIAS continues to provide ongoing support and maintenance, ensuring that the chatbot solution stays up-to-date with the latest AI advancements, further enhancing its performance and the overall customer service experience.`}
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="Group 2.png"
              iImg="icon02.png"
              ftitle="Innovating with a Unique Approach"
              descriptions="Our methodology distinguishes us in the field. We harmonize traditional chatbot design with dynamic AI capabilities, allowing our chatbots to deliver both pre-set and contextually learned responses to a broad spectrum of user inputs. Our chatbots are designed for continuous learning and improvement, enhancing their understanding of user intent and context with each interaction. This translates into relevant and accurate responses, adeptly handling a diverse range of tasks from answering intricate queries to engaging in casual conversation."
            />

            <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
