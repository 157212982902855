import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 clearfix">
              <img
                src={publicUrl + "assets/images/final/Asset 41.svg"}
                alt={imagealt}
                className="float-right wow fadeInLeft"
                data-wow-duration="1500ms"
              />
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="service-details__content">
                <h3>Real-Time Customer Support</h3>
                <p>
                  We provide round-the-clock, real-time support, ensuring your
                  customers always have assistance when they need it.
                </p>
                <ul className="list-unstyled video-one__list">
                  <li>
                    <i className="far fa-fire" />
                    <span style={{ fontWeight: "bold" }}>
                      24/7 Availability:
                    </span>
                    We offer consistent support, regardless of the time or day.
                  </li>
                  <li>
                    <i className="far fa-fire" />
                    <span style={{ fontWeight: "bold" }}>
                      Instant Responses:{" "}
                    </span>
                    Quick answers to user queries help improve customer
                    satisfaction by reducing wait times.
                  </li>
                  <li>
                    <i className="far fa-fire" />
                    <span style={{ fontWeight: "bold" }}>
                      Real-Time Support:
                    </span>
                    Our system ensures a more personalized customer experience
                    by providing support based on user intent recognition.
                  </li>
                  <li>
                    <i className="far fa-fire" />
                    <span style={{ fontWeight: "bold" }}>
                      Human Agent Intervention:
                    </span>
                    In complex scenarios, we enable a seamless transition from
                    bot interaction to a real agent, ensuring your customers
                    always feel heard and supported.
                  </li>
                </ul>
                {/* /.list-unstyled video-one__list */}
              </div>
              {/* /.service-details__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-lg-6">
              <img
                src={publicUrl + "assets/images/final/Asset 31.svg"}
                alt={imagealt}
                className="wow fadeInRight"
                data-wow-duration="1500ms"
              />
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="service-details__content">
                <h3>Ongoing Support and Maintenance</h3>
                <p>
                  Our commitment extends to your long-term success. We offer
                  continuous support and maintenance services to ensure your
                  solution delivers the desired results.
                </p>
                <ul className="list-unstyled video-one__list">
                  <li>
                    <i className="far fa-check" />
                    <span style={{ fontWeight: "bold" }}>Regular Updates:</span>
                    We keep your solution up-to-date, incorporating new features
                    and staying in sync with the latest AI advancements.
                  </li>

                  <li>
                    <i className="far fa-check" />
                    <span style={{ fontWeight: "bold" }}>
                      Performance Monitoring:
                    </span>
                    We stay on top of your solution's performance, identifying
                    and addressing any issues promptly to ensure seamless
                    operation.
                  </li>

                  <li>
                    <i className="far fa-check" />
                    <span style={{ fontWeight: "bold" }}>Client Support:</span>
                    Our dedicated support team is always ready to assist with
                    any concerns or questions.
                  </li>
                </ul>
                {/* /.list-unstyled service-details__list */}
              </div>
              {/* /.service-details__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default ServiceDetails;
