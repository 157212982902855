import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Service from './section-components/service-v6';

import Footer from './global-components/footer-v3';
import ServiceDetail from './section-components/service-details';
import Cta from './section-components/cta';

const ServiceV2 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Our Services"  />




        <section className="service-two service-two__service-page  go-top special_a" style={{paddingBottom:"0px !important"}}>
			  <div className="container">
			    <div className="row">
			      <div className="">
			        <div className="service-two__block">
			          <div className="block-title-two text-left">
			            <h3>At AIAS,</h3>
			          </div>{/* /.block-title-two */}
			          <p>We specialize in developing advanced chatbot solutions that are tailor-made for your business needs. Our services aim to elevate your customer interactions, streamline your operations, and enhance your growth potential. </p>
			      
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}
			    
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>


      



        <Service />
       <ServiceDetail></ServiceDetail>
	   <Cta></Cta>
        <Footer />
    </div>
}

export default ServiceV2

